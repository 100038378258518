<template>
  <div id="allCourseTestsCard" class="w-100">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card
      :loading="loading || superLoading"
      elevation="0"
      class="w-100 pb-4 px-1 px-md-4"
    >
      <!-- Title -->
      <h6 class="text-h6 pt-4">
        <v-icon class="mr-1" color="deep-purple"
          >mdi-clipboard-text-multiple</v-icon
        >
        Tests:
      </h6>

      <v-container>
        <!-- Error Row -->
        <v-row align="center" no-gutters v-if="error">
          <v-col cols="12" class="mt-2">
            <v-alert type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>

        <!-- Display Tests Row -->
        <v-row justify="center" align="center" v-if="allTests.length > 0">
          <v-container fluid class="pa-0">
            <!-- Tests List -->
            <v-row class="my-3" align="center" justify="center" no-gutters>
              <v-col cols="12" sm="12" md="9" class="mb-2">
                <v-list-item
                  v-for="test in allTests"
                  :key="test.id"
                  class="px-0"
                >
                  <v-list-item-avatar class="mr-2 mr-md-4">
                    <v-icon class="grey lighten-3" color="deep-purple">
                      mdi-clipboard-clock
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center">
                      <span class="mr-2">
                        {{ test.testName }}
                      </span>
                    </v-list-item-title>

                    <!-- Questions Count and Duration -->
                    <v-list-item-subtitle class="mt-2">
                      <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-chip class="mr-1" small>
                          <v-icon class="mr-1" small>mdi-card-text</v-icon>
                          <b class="mr-1">{{ test.questionCount }}</b> Questions
                        </v-chip>
                        <v-chip class="ml-1" small>
                          <v-icon class="mx-1" small>mdi-timer-sand</v-icon>
                          <b class="mr-1">{{ test.testDuration }}</b>
                          Mins
                        </v-chip>
                      </template>

                      <template v-else>
                        <v-chip class="mr-1" small>
                          <v-icon class="mr-1" small>mdi-card-text</v-icon>
                          <b class="mr-1">{{ test.questionCount }}</b>
                          <v-icon class="mx-1" small>mdi-timer-sand</v-icon>
                          <b class="mr-1">{{ test.testDuration }}</b>
                        </v-chip>
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <!-- Re-attempt and Result Button -->
                    <template v-if="test.id in results">
                      <v-btn
                        :color="
                          test.isPremium
                            ? 'amber--text text--darken-4 px-3'
                            : 'px-3'
                        "
                        elevation="1"
                        :to="
                          test.isPremium && !isUserSubscribed
                            ? '/premium-pass'
                            : {
                                name: 'Result',
                                params: { id: test.id },
                              }
                        "
                        rounded
                        small
                      >
                        <template v-if="test.isPremium">
                          <span v-if="isUserSubscribed">
                            Re-Attempt / Solution
                          </span>
                          <span v-else class="d-flex align-center">
                            <v-icon class="mr-2" size="17">mdi-lock</v-icon
                            >Unlock
                          </span>
                        </template>
                        <template v-else> Result / Solution </template>
                      </v-btn>
                    </template>

                    <!-- Attempt Button -->
                    <v-btn
                      v-else
                      :color="
                        test.isPremium
                          ? 'amber--text text--darken-4 px-3'
                          : 'px-3'
                      "
                      elevation="1"
                      :to="
                        test.isPremium && !isUserSubscribed
                          ? '/premium-pass'
                          : {
                              name: 'Test',
                              params: { id: test.id, testObj: test },
                            }
                      "
                      rounded
                      small
                    >
                      <template v-if="test.isPremium">
                        <span v-if="isUserSubscribed"> Attempt </span>
                        <span v-else class="d-flex align-center">
                          <v-icon class="mr-2" size="17">mdi-lock</v-icon>Unlock
                        </span>
                      </template>
                      <template v-else> Attempt </template>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>

            <!-- Navigation Buttons Row -->
            <v-row class="mt-4" align="center" no-gutters>
              <v-col cols="12" sm="12" class="d-flex justify-center">
                <v-btn
                  class="mx-1"
                  color="primary darken-2"
                  :loading="loading || superLoading"
                  @click="fetchAllCourseTests('prev')"
                  text
                >
                  <v-icon>mdi-chevron-left</v-icon> Previous
                </v-btn>
                <v-btn
                  class="mx-1"
                  color="primary darken-2"
                  :loading="loading || superLoading"
                  :disabled="allTests.length < 10"
                  @click="fetchAllCourseTests('next')"
                  text
                >
                  Next
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AllCourseTestsCard",
  props: ["superLoading", "selectedCourse", "isUserSubscribed"],
  components: {},
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    allTests: [],
    results: {},
    firstAndLastVisible: null,
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    fetchAllCourseTests(requestType) {
      var payload = "";

      switch (requestType) {
        case "prev":
          payload = {
            requestType,
            doc: this.firstAndLastVisible.firstVisible,
            course: this.selectedCourse,
          };
          break;
        case "next":
          payload = {
            requestType,
            doc: this.firstAndLastVisible.lastVisible,
            course: this.selectedCourse,
          };
          break;
        default:
          payload = { requestType: null, course: this.selectedCourse };
      }

      this.setLoading(true);

      this.$store
        .dispatch("getAllCourseTests", payload)
        .then((res) => {
          // write data if there is data
          if (res.tests.length > 0) {
            this.snackbarText = "Tests fetched successfully :)";
            this.allTests = res.tests;
            this.results = res.results;
            this.firstAndLastVisible = res.firstAndLastVisible;
          } else {
            //if there is no data show on snackbar accordingly
            switch (requestType) {
              case "prev":
                this.snackbarText = "This is the first page.";
                break;
              case "next":
                this.snackbarText = "This is the last page.";
                break;
              default:
                this.snackbarText = "No tests in this course yet :(";
                this.allTests = [];
                this.results = {};
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },
  },
  mounted() {
    this.fetchAllCourseTests();
  },
};
</script>
